<template>
  <form @submit.prevent="signUp" class="w-full mb-2">
    <h3 class="text-blue text-left"><b>Datos de contacto</b></h3>
    <div class="w-full">
      <BaseRadio
        name="personType"
        id="personType"
        label="Tipo de Persona"
        :radioOptions="formOptions.optionsPersonType"
        v-model="person_type"
        required="true"
      />
      <!-- Datos Persona -->
      <div v-if="person_type != ''" class="w-full">
        <!-- Persina Natural -->
        <div v-if="person_type == 1">
          <SignUpNaturalForm ref="form" @setForm="setForm" />
        </div>
        <!-- Persona Juridica -->
        <div v-if="person_type == 2" class="w-full">
          <SignUpJuridicaForm
            ref="form"
            @setForm="setForm"
            @setFiles="setFormFiles"
            :fileList="files"
          />
        </div>
      </div>
      <div class="w-full">
        <BaseRadio
          name="personalData"
          id="personalData"
          label="¿Autorizo el tratamiento de datos personales?"
          :radioOptions="optionsYN"
          v-model="personalData"
          required="true"
        />
        <BaseRadio
          name="sensitiveData"
          id="sensitiveData"
          label="¿Autorizo el tratamiento de datos sensibles?"
          :radioOptions="optionsYN"
          v-model="sensitiveData"
          required="true"
        />
      </div>
    </div>
    <div
      v-if="form.personType != ''"
      class="form-group w-full mt-5 text-left flex flex-col"
    >
      <p class="my-3 text-xs text-center">
        Consulte la
        <a
          class="text-blue"
          href="https://www.superfinanciera.gov.co/inicio/nuestra-entidad/acerca-de-la-sfc/politicas-de-la-entidad/politicas-web-10106783"
          target="_blank"
          ><b
            >Política de tratamiento de datos personales y sensibles de la
            SFC</b
          ></a
        >
      </p>
      <div v-if="feedback">
        <p class="mt-2 mb-4 text-sm text-center text-red-500">{{ feedback }}</p>
      </div>
      <div class="flex flex-col gap-3">
        <a-button
          type="primary"
          shape="round"
          html-type="submit"
          :block="true"
          :loading="loading"
          :disabled="invalidForm && disableButton"
          >REGISTRARSE</a-button
        >
        <a-button
          type="danger"
          shape="round"
          :block="true"
          ghost
          @click.native="$router.go(-1)"
          >VOLVER</a-button
        >
        <!-- <BaseButton @click.native="$router.go(-1)" type="button" label="VOLVER" danger="true" /> -->
      </div>
    </div>
  </form>
</template>

<script>
import SignUpNaturalForm from "@/components/Login/SignUpNaturalForm";
import SignUpJuridicaForm from "@/components/Login/SignUpJuridicaForm";
import capitalising from "@/utils/checkForms";

export default {
  props: ["onSubmit"],
  components: {
    SignUpNaturalForm,
    SignUpJuridicaForm,
  },
  mounted() {},
  data() {
    return {
      person_type: 1,
      form: {},
      files: [],
      feedback: "",
      personalData: "",
      sensitiveData: "",
      disableButton: false,
      loading: false,
      optionsYN: [
        { id: 1, name: "Sí" },
        { id: 2, name: "No" },
      ],
    };
  },
  methods: {
    async signUp() {
      this.loading = true;
      this.disableButton = true;
      if (this.validateRequiredField() && this.checkForm()) {
        let form = {
          person_type: this.person_type,
          ...this.form,
          email: this.form.email.toLowerCase(),
          emailConfirmation: this.form.emailConfirmation.toLowerCase(),
          first_name: capitalising(this.form.first_name),
          last_name: capitalising(this.form.last_name),
        };
        delete form.identification_number_confirmation
        // Aditional modification
        if (form.person_type === 2) {
          form.business_name = capitalising(form.business_name);
        }
        let { data, error } = await this.$api.signup(form, this.files);
        if (error) {
          this.loading = false;
          this.disableButton = false;
          if (error.errors) {
            this.feedback = error.errors?.length > 0 ? error.errors[0] : error;
          } else {
            this.feedback = error.error ? error.error : error;
          }
        }
        if (data) {
          this.$store
            .dispatch("session/login", {
              email: this.form.email.toLowerCase(),
              password: this.form.password,
            })
            .then(() => {
              this.$router.push({ name: "Verify" });
            });
        }
      } else {
        this.loading = false;
        this.disableButton = false;
        return;
      }
    },
    setForm(form) {
      this.form = form;
      this.disableButton = this.checkForm();
    },
    setFormFiles(files) {
      this.files = files;
    },
    checkForm() {
      let valid = false;
      let regex = /[^a-zñÑ\s]/i;
      let regexNIT = /[0-9]{7,}\-[0-9]{1}/;
      if (
        regex.test(this.form.first_name + "") ||
        regex.test(this.form.last_name + "")
      ) {
        this.feedback =
          "En los campos nombres y apellidos no se permiten caracteres especiales ni acentos";
        return valid;
      }
      if (regex.test(this.form.business_name + "")) {
        this.feedback =
          "En el campo razón social no se permite caracteres especiales ni acentos";
        return valid;
      }
      if (
        this.form.identification_number &&
        this.form.identification_type == 1 &&
        !this.$validator.isNumeric(this.form.identification_number + "")
      ) {
        this.feedback = "Ingrese un número válido de documento";
        return valid;
      }
      // Validación para NIT
      if (
        this.form.identification_type == 3 &&
        !regexNIT.test(this.form.identification_number + "")
      ) {
        this.feedback = "Ingrese un NIT válido";
        return valid;
      }
      if ((this.form.identification_number + "").length > 15) {
        this.feedback =
          "La longitud máxima del número de identificación debe ser 15.";
        return valid;
      }
      if (
        this.form.identification_number &&
        !this.$validator.equals(
          this.form.identification_number,
          this.form.identification_number_confirmation
        )
      ) {
        this.feedback = "Los números de identificación no coinciden";
        return valid;
      }
      if (this.form.phone && this.form.phone.toString().length < 7) {
        this.feedback = "Ingrese un número de teléfono válido.";
        return valid;
      }

      if (this.form.phone && this.form.phone.toString().length > 15) {
        this.feedback = "El número de teléfono no puede ser mayor a 15.";
        return valid;
      }
      if (this.form.email && !this.$validator.isEmail(this.form.email)) {
        this.feedback = "Ingrese un correo válido";
        return valid;
      } else if (
        this.form.emailConfirmation &&
        !this.$validator.equals(this.form.email, this.form.emailConfirmation)
      ) {
        this.feedback = "El correo electrónico no coincide";
        return valid;
      }
      if (
        this.form.password &&
        !this.$validator.isStrongPassword(this.form.password)
      ) {
        this.feedback =
          "La contraseña no es segura. Debe contener mínimo: 8 digitos, 1 letra minúscula, 1 letra mayúscula, 1 número, 1 símbolo";
        return valid;
      }
      if (
        this.form.password &&
        !this.$validator.equals(
          this.form.password,
          this.form.passwordConfirmation
        )
      ) {
        this.feedback = "La contraseña no coincide";
        return valid;
      }
      if (this.person_type == 2 && this.files.length == 0) {
        this.feedback =
          "Debe adjuntar el poder que lo identifique como representante de la empresa";
        return valid;
      }
      this.feedback = "";
      valid = true;
      return valid;
    },
    validateRequiredField() {
      let refs = this.$refs.form.$refs;
      let valid = false;
      this.feedback = "";
      if (Object.keys(this.form).length === 0) {
        this.feedback = "Todos los campos son obligatorios";
        return valid;
      }
      for (let ref in refs) {
        if (
          (refs[ref] && refs[ref].required && refs[ref].value === "") ||
          refs[ref]?.invalid
        ) {
          let y =
            refs[ref].$el.getBoundingClientRect().top + window.scrollY - 100;
          window.scrollTo(0, y);
          refs[ref].setInvalid(true);
          return valid;
        }
      }
      return true;
    },
  },
  computed: {
    invalidForm: function () {
      let invalid = true;
      if (this.personalData == 2 || this.sensitiveData == 2) {
        this.feedback =
          "Para registrarse debe aprobar los términos y condiciones";
      } else if (this.personalData == 1 && this.sensitiveData == 1) {
        invalid = false;
        this.feedback = "";
      }
      return invalid;
    },
    formOptions: function () {
      return this.$store.state.form;
    },
  },
};
</script>

<style></style>
